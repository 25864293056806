<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col xs="12" sm="12" md="12">
            <ValidationProvider name="Comments" vid="comments_id" rules="required" v-slot="{ errors }">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="comments_id"
                >
                <template v-slot:label>
                {{ $t('globalTrans.comments') }} <span class="text-danger">*</span>
                </template>
                <b-form-checkbox-group
                    id="comments_id"
                    v-model="formData.comments_id"
                    :options="noteList"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-checkbox-group>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Attachment" vid="attachment" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="attachment">
                <template v-slot:label>
                  {{ $t('license_management.attachment') }}  <span class="text-danger">*</span>
                </template>
                <b-form-file
                  name="attachment"
                  v-model="formData.attachment"
                  @change="onChange"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-inspection')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { tcbInspectionStore } from '../../api/routes'

export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      formData: {
        note_en: '',
        note_bn: '',
        attachment: ''
      },
      commentList: [
                      {
                          value: 1,
                          text_en: 'The applicant is a genuine grocer',
                          text_bn: 'আবেদনকারী প্রকৃত মুদি ব্যবসায়ী '
                      },
                      {
                          value: 2,
                          text_en: 'The applicant have an up-to-date trade license as a grocer',
                          text_bn: 'মুদি ব্যবসায়ী হিসেবে হালনাগাদ ট্রেড লাইসেন্স আছে'
                      },
                      {
                          value: 3,
                          text_en: 'There is a grocery store',
                          text_bn: 'মুদি দোকান আছে '
                      },
                      {
                          value: 4,
                          text_en: 'Store has adequate storage space for 03-04 May: tons of products',
                          text_bn: 'দোকানে ০৩-০৪ মে:টন পণ্য ধারণের উপযোগী স্থান আছে '
                      }
                  ]
    }
  },
  created () {
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    noteList: function () {
        return this.commentList.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn, text_en: item.text_en, text_bn: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en, text_en: item.text_en, text_bn: item.text_bn }
            }
          })
    }
  },
  methods: {
    onChange (e) {
      const selectedFile = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.formData[e.target.name] = event.target.result
      }
      reader.readAsDataURL(selectedFile)
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const storeData = Object.assign(this.formData, { tcb_application_id: this.id })
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, tcbInspectionStore, storeData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-inspection')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
